<template>
  <el-main>
    <div class="search">
      <div class="left">
        <span>隔离点所在位置</span>
      </div>
      <el-form class="right" label-width="80px">
        <el-form-item label="关键字：">
          <el-input v-model="name" placeholder="请输入关键字..."></el-input>
        </el-form-item>
        <el-button type="primary" @click="getList">搜索</el-button>
        <el-button @click="cancelSearch">清除搜索</el-button>
        <template v-if="userInfo.role_id == 1">
          <el-button @click="add">添加</el-button>
          <el-button>导出</el-button>
          <el-button>导入</el-button>
        </template>
      </el-form>
    </div>
    <el-table :data="list" row-key="id"
      ><el-table-column prop="id" label="编号" align="left"></el-table-column>
      <el-table-column prop="name" label="隔离点" align="center">
      </el-table-column>
      <el-table-column prop="p_name" label="隔离点代号" align="center">
        <!-- <template v-slot="{ row }">
          <el-checkbox v-model="row.checkbox">{{ row.p_name }}</el-checkbox>
        </template> -->
      </el-table-column>
      <el-table-column prop="address" label="隔离点位置" align="center">
      </el-table-column>
      <el-table-column
        prop="contact_number"
        label="电话"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="temperature"
        label="当前体温"
        align="center"
      ></el-table-column>
      <el-table-column prop="goods_name" label="创建日期" align="center">
        <template v-slot="{ row }">
          {{ getDateformat(row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column prop="goods_name" label="操作" align="center">
        <template v-slot="{ row }">
          <el-button size="small" @click="handleEdit(row)">编辑</el-button>
          <el-button
            v-if="userInfo.role_id == 1"
            size="small"
            type="danger"
            @click="handleDel(row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-size="rows"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total_number"
    >
    </el-pagination>
    <el-dialog
      title="添加隔离位置"
      :visible.sync="showAdd"
      :before-close="handleClose"
      width="600px"
    >
      <el-form ref="form" :model="addForm" :rules="rules" label-width="130px">
        <el-form-item label="隔离点名称：" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="负责人名称：" prop="responsible_person">
          <el-input v-model="addForm.responsible_person"></el-input>
        </el-form-item>
        <el-form-item label="联系方式：" prop="contact_number">
          <el-input v-model.number="addForm.contact_number"></el-input>
        </el-form-item>
        <el-form-item label="省市区：" class="city">
          <div class="box">
            <el-select
              v-model="province_name"
              placeholder="请选择"
              @change="(val) => sonArea(0, val)"
            >
              <el-option
                v-for="(item, index) in area"
                :key="index"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
            <el-select
              v-model="city_name"
              placeholder="请选择"
              @change="(val) => sonArea(1, val)"
            >
              <el-option
                v-for="(item, index) in cityArr"
                :key="index"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
            <el-select
              v-model="area_name"
              placeholder="请选择"
              @change="(val) => sonArea(2, val)"
            >
              <el-option
                v-for="(item, index) in areaArr"
                :key="index"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="酒店地址：" prop="address">
          <el-input v-model="addForm.address"></el-input>
        </el-form-item>
        <el-form-item label="工作人员：" prop="teacher_id">
          <el-select
            v-model="addForm.teacher_id"
            filterable
            placeholder="请选择工作人员"
            style="width: 100%"
          >
            <el-option
              v-for="item in teacherList"
              :key="item.id"
              :label="item.teacher"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="comfirmAdd">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import { getDateformat } from "@/utils/getDate";
export default {
  data() {
    let checkPhone = (rule, value, callback) => {
      let reg = /^1[345789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入11位手机号"));
      } else {
        callback();
      }
    };
    return {
      page: 1,
      rows: 10,
      name: "",
      list: [],
      teacherList: [],
      total_number: 0,
      showAdd: !1,
      area_name: "",
      province_name: "",
      city_name: "",
      area: [],
      cityArr: [],
      areaArr: [],
      type: 0,
      addForm: {
        contact_number: "",
        name: "",
        area_id: "",
        address: "",
        teacher_id: "",
        responsible_person: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入隔离点名称", trigger: "blur" },
        ],
        responsible_person: [
          { required: true, message: "请输入负责人名称", trigger: "blur" },
        ],
        contact_number: [
          { required: true, message: "请输入联系方式", trigger: "blur" },
          {
            type: "number",
            validator: checkPhone,
            message: "请输入11位有效手机号码",
            trigger: "blur",
          },
        ],
        address: [
          { required: true, message: "请输入酒店地址", trigger: "blur" },
        ],
        teacher_id: [
          { required: true, message: "请选择工作人员", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  created() {
    this.getList();
    this.getArea();
    this.getTeacherList();
  },
  methods: {
    getList() {
      let data = {
        page: this.page,
        rows: this.rows,
      };
      if (this.name) data.name = this.name;
      this.$axios.post(this.$api.government.list, data).then((res) => {
        if (res.code == 0) {
          let list = res.result.list;
          list.map((item) => {
            item.checkbox = !1;
            item.teacher_info = item.teacher_info || {};
            item.shipper_info = item.shipper_info || {};
          });
          this.list = list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 获取省市区信息
    getArea() {
      let that = this;
      this.$axios
        .get(this.$api.area, {
          tree: 1,
          level: 2,
        })
        .then((res) => {
          if (res.code == 0) {
            that.area = res.result.list;
          }
        });
    },
    cancelSearch() {
      this.name = "";
      this.getList();
    },
    handleClose() {
      if (this.type == 0) this.$refs["form"].resetFields();
      this.showAdd = !1;
    },
    //获取医护人员
    getTeacherList() {
      this.$axios.post(this.$api.teacher.list, { rows: 1000 }).then((res) => {
        if (res.code == 0) {
          let list = res.result.list;
          this.teacherList = list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 三级联动选择地区
    sonArea(status, val) {
      if (status == 0) {
        let index = this.area.find((item) => item.name == val);
        this.cityArr = index._child;
        this.province_name = index.name;
      } else if (status == 1) {
        let index = this.cityArr.find((item) => item.name == val);
        this.areaArr = index._child;
        this.city_name = index.name;
      } else if (status == 2) {
        let index = this.areaArr.find((item) => item.name == val);
        this.area_name = index.name;
        this.addForm.area_id = index.id;
      }
    },
    handleSizeChange(val) {
      this.rows = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    handleEdit(row) {
      this.type = 1;
      this.addForm = row;
      this.showAdd = !0;
      let combine_detail = row.combine_detail.split(" ");
      this.sonArea(0, combine_detail[0]);
      this.sonArea(1, combine_detail[1]);
      this.sonArea(2, combine_detail[2]);
    },
    //删除隔离位置
    handleDel(id) {
      this.$confirm("确认删除此隔离位置？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios.post(this.$api.government.del, { id: id }).then((res) => {
          if (res.code == 0) {
            this.$message.success("删除成功");
            this.list = this.list.filter((item) => item.id != id);
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    add() {
      for (let i in this.addForm) this.addForm[i] = "";
      this.showAdd = !0;
      this.type = 0;
    },

    //确认添加
    comfirmAdd() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let url = this.type
            ? this.$api.government.edit
            : this.$api.government.add;
          this.$axios.post(url, this.addForm).then((res) => {
            if (res.code == 0) {
              this.showAdd = !1;
              this.$message.success(this.type ? "编辑成功" : "添加成功");
              this.getList();
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.el-main {
  color: #333;
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .search {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    .left {
      font-weight: bold;
      font-size: 18px;
      span:nth-child(2) {
        margin-left: 4px;
        color: #1e92ff;
      }
    }
    .right {
      display: flex;
      .el-form-item {
        margin: 0;
      }
      .el-input {
        width: 270px;
        margin-right: 20px;
      }
      .el-button {
        border-radius: 19px;
      }
      .el-button--default {
        border-color: #409eff;
      }
    }
  }
  .el-table {
    border-top: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
    margin-bottom: 10px;
  }
  .city .box {
    display: flex;
    justify-content: space-between;
    .el-select {
      flex: 1;
      margin-right: 10px;
      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>
